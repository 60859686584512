import { useQuery } from '@apollo/react-hooks';
import { useMemo } from 'react';

import type { StaticContext } from '@atlassian/forge-conditions';

import { GlobalOperationsQuery } from '@confluence/global-operations';
import { useSessionData } from '@confluence/session-data';
import { getIsProductAdminFromQuery, getIsSiteAdminFromQuery } from '@confluence/current-user';

import { forgeGlobalExtensions } from '../ForgeModuleType';
import type { ForgeModules } from '../ForgeModules';

import { UserPrivilegeQuery } from './UserPrivilegeQuery.graphql';
import { DisplayConditionsSpaceAdminQuery } from './DisplayConditionsSpaceAdminQuery.graphql';

export interface UseStaticContextHookResult {
	staticContext?: StaticContext;
	loading: boolean;
	error?: Error;
}

interface UseStaticContextProps {
	moduleType: ForgeModules;
	spaceKey?: string;
}

export function useStaticContext({
	moduleType,
	spaceKey,
}: UseStaticContextProps): UseStaticContextHookResult {
	const { isLoggedIn } = useSessionData();

	const isGlobalExtension = forgeGlobalExtensions.includes(moduleType);

	const {
		data: spaceAdminData,
		loading: spaceAdminLoading,
		error: spaceAdminError,
	} = useQuery(DisplayConditionsSpaceAdminQuery, {
		variables: {
			spaceKey,
		},
		skip: !spaceKey || isGlobalExtension,
	});

	const {
		data: globalOpsData,
		loading: globalOpsLoading,
		error: globalOpsError,
	} = useQuery(GlobalOperationsQuery);
	let canViewUserProfiles = false;
	if (globalOpsData) {
		canViewUserProfiles = globalOpsData.globalOperations.some(
			(operation) =>
				operation && operation.operation === 'read' && operation.targetType === 'userProfile',
		);
	}

	const {
		data: privilegeData,
		loading: privilegeLoading,
		error: privilegeError,
	} = useQuery(UserPrivilegeQuery, {
		skip: globalOpsLoading || !!globalOpsError || !canViewUserProfiles,
	});
	const isSpaceAdmin = spaceAdminData?.space?.currentUser?.isAdmin;
	const isSiteAdmin = getIsSiteAdminFromQuery(privilegeData);
	const isAdmin = isSiteAdmin || getIsProductAdminFromQuery(privilegeData);
	const staticContext = useMemo(
		() => ({
			isSpaceAdmin,
			isLoggedIn,
			isAdmin,
			isSiteAdmin,
		}),
		[isSpaceAdmin, isLoggedIn, isAdmin, isSiteAdmin],
	);

	if (spaceAdminLoading || spaceAdminError) {
		return { loading: spaceAdminLoading, error: spaceAdminError };
	}

	if (globalOpsLoading || globalOpsError) {
		return { loading: globalOpsLoading, error: globalOpsError };
	}

	if (privilegeLoading || privilegeError) {
		return { loading: privilegeLoading, error: privilegeError };
	}

	return {
		staticContext,
		loading: false,
	};
}
