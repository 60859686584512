import { useEffect, useMemo } from 'react';

import {
	useExtensionList as useExtensionListExternal,
	GQLExtensionContextsFilterType,
} from '@atlassian/forge-ui/provider';
import type {
	ForgeUIExtensionQueryOptions,
	UseExtensionListOptions,
} from '@atlassian/forge-ui/provider';

import { getAGGClient, markErrorAsHandled } from '@confluence/graphql';
import { useSessionData } from '@confluence/session-data';
import { useSpaceDetail } from '@confluence/space-utils';
import { usePageSpaceKey } from '@confluence/page-context';
import { useIsExternalCollaborator } from '@confluence/external-collab-ui/entry-points/useIsExternalCollaborator';
import { isUnauthorizedError, isTooManyRequestsError } from '@confluence/error-boundary';

import { useExtensionsFilteredByDisplayConditions } from '../display-conditions';
import type { ForgeModules } from '../ForgeModules';
import {
	FORGE_MODULE_BYLINE,
	FORGE_MODULE_CONTENT_ACTION,
	FORGE_MODULE_CONTEXT_MENU,
	FORGE_MODULE_CUSTOM_CONTENT,
	FORGE_MODULE_SPACE_SETTINGS,
	FORGE_MODULE_SPACE_PAGE,
} from '../ForgeModuleType';
import { isBlockedByAppAccessRule } from '../utils';

import { useWorkspaceARI } from './useWorkspaceARI';

const supportedAccessNarrowingModules = new Set<ForgeModules>([
	FORGE_MODULE_BYLINE,
	FORGE_MODULE_CONTENT_ACTION,
	FORGE_MODULE_CONTEXT_MENU,
	FORGE_MODULE_CUSTOM_CONTENT,
	FORGE_MODULE_SPACE_PAGE,
	FORGE_MODULE_SPACE_SETTINGS,
]);

export type UseExtensionListProps = {
	moduleType: ForgeModules;
	queryOptions?: ForgeUIExtensionQueryOptions;
	expandAppOwner?: boolean;
	returnBlockedExtensions?: boolean;
};

export const useExtensionList = ({
	moduleType,
	queryOptions,
	expandAppOwner = false,
	returnBlockedExtensions = false,
}: UseExtensionListProps) => {
	const { featureFlagClient, userId } = useSessionData();
	const [spaceKey] = usePageSpaceKey();
	const {
		data: spaceData,
		loading: spaceLoading,
		error: spaceError,
	} = useSpaceDetail(spaceKey, featureFlagClient);
	const workspaceARI = useWorkspaceARI();

	const dataClassificationTags = spaceData?.dataClassificationTags;

	const { isExternalCollaborator } = useIsExternalCollaborator();
	const isAnonymous = !userId;

	const options: UseExtensionListOptions = {
		client: getAGGClient(),
		contextIds: [workspaceARI],
		type: moduleType,
		queryOptions: {
			...queryOptions,
			skip: isAnonymous || isExternalCollaborator || spaceLoading || !!spaceError,
		},
		expandAppOwner,
	};

	const isAccessNarrowingFlagEnabled =
		!!featureFlagClient?.getBooleanValue('confluence.frontend.ecosystem.access.narrrowing', {
			default: false,
		}) && supportedAccessNarrowingModules.has(moduleType);

	// Passing the `filter` key will use the newer extension() resolver over the extensionByType() resolver
	// Going forward we to use the newer resolver
	// So remove this check when removing the flag: "confluence.frontend.ecosystem.access.narrrowing"
	if (isAccessNarrowingFlagEnabled) {
		options.filter = [
			{
				type: GQLExtensionContextsFilterType.ExtensionType,
				value: [moduleType],
			},
		];

		options.dataClassificationTagIds = dataClassificationTags;
	}

	const {
		extensions: extsData,
		loading: extsLoading,
		error: extsError,
	} = useExtensionListExternal(options);

	// Filter out extensions blocked by App Access Rule if necessary
	const extsDataFilteredByAppAccessRule = useMemo(
		() =>
			extsData && !returnBlockedExtensions
				? extsData.filter((ext) => !isBlockedByAppAccessRule(ext))
				: extsData,
		[extsData, returnBlockedExtensions],
	);

	useEffect(() => {
		// suppress 403 errors as we can't do much with them
		if (extsError && isUnauthorizedError(extsError)) {
			markErrorAsHandled(extsError);
		}

		// suppress 429 errors for offending users
		if (isTooManyRequestsError(extsError)) {
			markErrorAsHandled(extsError);
		}

		if (spaceError) {
			markErrorAsHandled(spaceError);
		}
	}, [extsError, spaceError]);

	const { extensions, loading, error } = useExtensionsFilteredByDisplayConditions(
		moduleType,
		extsDataFilteredByAppAccessRule,
		extsLoading || spaceLoading,
		extsError || spaceError,
	);

	return {
		extensions,
		loading,
		error,
	};
};
